import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { USERS_PATH } from '@/core/constants/api.constants';
import { Paths } from '@/core/constants/routes.constants';
import { Credentials, SignUpCredentials } from '@/core/types/auth.types';
import { showNotification } from '@/core/utils/show-notification/show-notification';

export const useAuthUser = () => {
  const router = useRouter();
  const handleLogin = ({ email, password, access_token = '' }: Credentials) => {
    return signIn(
      'credentials',
      {
        username: email,
        password,
        callbackUrl: window?.location?.origin,
        redirect: false
      },
      { access_token }
    );
  };

  const handleSignUp = ({ email, password, token }: SignUpCredentials) => {
    return api.POST(USERS_PATH, {
      params: {
        query: {
          signup_token: token
        }
      },
      body: {
        password,
        email,
        auth_method: 'email'
      }
    });
  };

  const login = useMutation({
    mutationFn: handleLogin,
    onSettled: (data) => {
      if (data?.status !== 200) {
        return showNotification({
          title: 'Invalid login credentials',
          type: 'error'
        });
      } else {
        router.push({
          pathname: Paths.ROOT
        });
      }
    }
  });

  const signUp = useMutation({
    mutationFn: handleSignUp,
    onSuccess: (data, variables) => {
      login.mutate(variables);
    },
    onError: (res: Response) => defaultErrorHandler(res, 'Error signing up!')
  });

  return { login, signUp };
};
