import {
  Anchor,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Group,
  Stack,
  Text,
  Title
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBrandGithub,
  IconBrandGoogle,
  IconBrandWalmart,
  IconBrandWindows
} from '@tabler/icons-react';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { useEffect } from 'react';

import { OktaIcon } from '@/core/constants/brands.icons.constants';
import { LogoWithText } from '@/core/constants/galileo-logos.constants';
import { SignInUpTexture } from '@/core/constants/icons.constants';
import { Paths } from '@/core/constants/routes.constants';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { showNotification } from '@/core/utils/show-notification/show-notification';

interface AuthPageLayoutProps {
  children: React.ReactNode;
  isLogin?: boolean;
  githubEnabled?: boolean;
  googleEnabled?: boolean;
  oktaEnabled?: boolean;
  activeDirectoryEnabled?: boolean;
  customSSOEnabled?: boolean;
  callbackUrl?: string;
  withoutOAuth?: boolean;
  displayRequestInvite?: boolean;
}

/**
 * AuthPageLayout
 *
 *
 *
 * @returns {React.Component} AuthPageLayout
 */
const AuthPageLayout = ({
  children,
  isLogin,
  displayRequestInvite,
  githubEnabled,
  googleEnabled,
  oktaEnabled,
  customSSOEnabled,
  activeDirectoryEnabled,
  callbackUrl,
  withoutOAuth
}: AuthPageLayoutProps) => {
  const router = useRouter();
  const { hideCredentialsLogin, hideRequestAccess } = useFeatureFlags();

  const isMedBreakpoint = useMediaQuery(`(max-width: 1100px)`);

  const capitalizedAuthCopy = `Sign ${isLogin ? 'in' : 'up'} with`;
  const header = isLogin ? 'Welcome back!' : "Let's get started";

  const hasSocialAuth = [
    githubEnabled,
    googleEnabled,
    oktaEnabled,
    activeDirectoryEnabled,
    customSSOEnabled
  ].some(Boolean);

  const signinAuthorizationParams = {
    signup_token: (router?.query?.token as string) || ''
  };

  const needsSignupToken = router?.query?.needsSignupToken;
  const errorDetail = router?.query?.errorDetail;

  useEffect(() => {
    if (needsSignupToken) {
      showNotification({
        title: 'Error signing up!',
        message:
          'No signup token provided. Please ask an admin to send a new invitation link.',
        type: 'error'
      });
      // Clear query params
      router.push(router.pathname);
    }
  }, [needsSignupToken]);

  useEffect(() => {
    if (errorDetail) {
      showNotification({
        title: 'Error signing up!',
        message: errorDetail,
        type: 'error'
      });
      // Clear query params
      router.push(router.pathname);
    }
  }, [errorDetail]);

  return (
    <Center bg='gray.0' h='100vh' w='100vw'>
      <Group h='100%' justify='space-around' style={{ zIndex: 1 }} w='100%'>
        <Card
          p='lg'
          radius='md'
          style={{
            width: isMedBreakpoint ? 350 : 400,
            boxShadow: '0px 0px 12px rgba(28, 27, 34, 0.12)'
          }}
        >
          <Card.Section
            inheritPadding
            withBorder
            className='text-center'
            py='lg'
            style={{
              borderColor: '#E9E8ED'
            }}
          >
            <Title order={2}>{header}</Title>
            <Text c='dimmed' size='md'>
              Evaluate, monitor and safeguard your Gen AI apps.
            </Text>
          </Card.Section>

          <Card.Section
            inheritPadding
            withBorder
            py='xl'
            style={{
              borderColor: '#E9E8ED'
            }}
          >
            {!withoutOAuth && hasSocialAuth && (
              <>
                <Box mb='sm'>
                  {customSSOEnabled && (
                    <Button
                      fullWidth
                      color='dark'
                      data-testid='custom-oauth'
                      leftSection={<IconBrandWalmart size={16} />}
                      size='md'
                      onClick={() =>
                        signIn(
                          'custom',
                          {
                            callbackUrl: callbackUrl || window?.location?.origin
                          },
                          signinAuthorizationParams
                        )
                      }
                    >
                      {`${capitalizedAuthCopy} SSO`}
                    </Button>
                  )}
                  {googleEnabled && (
                    <Button
                      fullWidth
                      color='red.5'
                      data-testid='google-oauth'
                      leftSection={<IconBrandGoogle size={16} />}
                      mt='xs'
                      size='md'
                      onClick={() =>
                        signIn(
                          'google',
                          {
                            callbackUrl: callbackUrl || window?.location?.origin
                          },
                          signinAuthorizationParams
                        )
                      }
                    >{`${capitalizedAuthCopy} Google`}</Button>
                  )}
                  {githubEnabled && (
                    <Button
                      fullWidth
                      color='dark'
                      data-testid='github-oauth'
                      leftSection={<IconBrandGithub size={16} />}
                      mt='xs'
                      size='md'
                      onClick={() =>
                        signIn(
                          'github',
                          {
                            callbackUrl: callbackUrl || window?.location?.origin
                          },
                          signinAuthorizationParams
                        )
                      }
                    >
                      {`${capitalizedAuthCopy} Github`}
                    </Button>
                  )}
                  {oktaEnabled && (
                    <Button
                      fullWidth
                      color='dark'
                      data-testid='okta-oauth'
                      leftSection={<OktaIcon />}
                      mt='xs'
                      size='md'
                      onClick={() =>
                        signIn(
                          'okta',
                          {
                            callbackUrl: callbackUrl || window?.location?.origin
                          },
                          signinAuthorizationParams
                        )
                      }
                    >{`${capitalizedAuthCopy} Okta`}</Button>
                  )}

                  {activeDirectoryEnabled && (
                    <Button
                      fullWidth
                      color='indigo.5'
                      data-testid='active-directory-oauth'
                      leftSection={<IconBrandWindows size={16} />}
                      mt='xs'
                      size='md'
                      onClick={() =>
                        signIn(
                          'azure-ad',
                          {
                            callbackUrl: callbackUrl || window?.location?.origin
                          },
                          signinAuthorizationParams
                        )
                      }
                    >
                      {`${capitalizedAuthCopy} Active Directory`}
                    </Button>
                  )}
                </Box>

                {!hideCredentialsLogin.isEnabled && (
                  <Divider
                    color='gray.2'
                    label={
                      <Text
                        c='gray.6'
                        tt='lowercase'
                      >{`or ${capitalizedAuthCopy} credentials`}</Text>
                    }
                    labelPosition='center'
                    mt='lg'
                  />
                )}
              </>
            )}

            {children}
          </Card.Section>
          {displayRequestInvite && !hideRequestAccess.isEnabled && (
            <Card.Section inheritPadding py='lg'>
              <Box className='align-center justify-center'>
                <Text>Don&apos;t have an account?</Text>
                <Anchor c='brand.6' fw={600} href={Paths.REQUEST_INVITE} ml={4}>
                  Request an invitation
                </Anchor>
              </Box>
            </Card.Section>
          )}
          {!isLogin && (
            <Card.Section inheritPadding py='lg'>
              <Box className='align-center justify-center'>
                <Text>Already a user?</Text>
                <Anchor c='brand.6' fw={600} href={Paths.SIGN_IN} ml={4}>
                  Sign in
                </Anchor>
              </Box>
            </Card.Section>
          )}
        </Card>
        <Stack
          gap='xl'
          justify='left'
          mb='md'
          w={isMedBreakpoint ? 350 : 'auto'}
        >
          <Box mx='0' ta='left'>
            <LogoWithText
              data-testid='galileo-logo-with-text'
              height={40}
              width={120}
            />
          </Box>
          <Title c='gray.8' order={2} pb='sm' size='md'>
            Build Trustworthy Generative AI and Natural Language Applications
            <Text c='dimmed' fw={400} size='sm'>
              Built for AI Developers working with LLMs and NLP Datasets:
            </Text>
          </Title>
          <Text className='pipe' fw={700} pb='sm' size='sm'>
            Reduce LLM Hallucinations, get advanced RAG analytics and improve
            LLM output quality
          </Text>
          <Text className='pipe' fw={700} pb='sm' size='sm'>
            Build AI guardrails to safeguard your LLM Applications
          </Text>
          <Text className='pipe' fw={700} size='sm'>
            Experiment 10x faster with a Customizable Experimentation and
            Evaluation Bench
          </Text>
        </Stack>
      </Group>
      <Box
        bottom={0}
        h='100vh'
        pos='absolute'
        right={0}
        style={{ zIndex: 0 }}
        w='100vw'
      >
        <SignInUpTexture />
      </Box>
    </Center>
  );
};

export default AuthPageLayout;
