import { AppShell, Box } from '@mantine/core';
import React from 'react';

export default function MinimalLayout({ children }: any) {
  return (
    <AppShell withBorder={false}>
      <AppShell.Main>
        <Box h='100vh' w='100vw'>
          {children}
        </Box>
      </AppShell.Main>
    </AppShell>
  );
}
